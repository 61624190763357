import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { getCurrentDonationsAsync, updateDonationsAsync } from '../utils/apiHelper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import FilledInput from '@mui/material/FilledInput';
import Input from '@mui/material/Input';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitSummary from './SubmitSummary';
import Alert from '@mui/material/Alert';
import  { useHistory } from 'react-router-dom'
import consts from "../utils/consts";
import { useLoading } from "../Hook";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Expired from './Expired';
import { makeStyles } from "@material-ui/core/styles";
import LoadingDialog from '../Loading/LoadingDialog';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  Container: {
    textAlign: "left",
    marginTop: 90
  },
	FormCard: {
		marginTop: 30,
    marginBottom: 10
  },
  SubmitButton: {
    marginTop: 20,
    marginBottom: 20
  },
  Divider: {
    fontWeight: "bold",
    marginTop: 20
  }
}));

export default function HelpingHandForm(props) {
  const classes = useStyles(props);
  const [donation, setDonation] = React.useState({
    "d133": 0,
    "d058": 0,
    "d060": 0,
    "d063": 0,
    "d064": 0,
    "d065": 0,
    "d068": 0,
    "d118": 0,
    "d119": 0,
    "d131": 0,
    "d066": 0,
    "isEvenlyDistributed": false
  });
  const [dialogOpen, setDialog] = React.useState(false);
  const [loadingOpen, setLoading] = React.useState(false);
  let history = useHistory();
  // const progressBar = useLoading();
  const isFormDisabled = process.env.REACT_APP_FORM_DISABLED === 'true' ? true : false;

  React.useEffect(() => {
    if (props.userProfile?.userPrincipalName) {
      // progressBar.show("Loading...");
      try {
        const fetchDonation = async () => {
          setLoading(true);
          var currentDonation = await getCurrentDonationsAsync();
          setDonation({...currentDonation});
          setLoading(false);
        }
        fetchDonation().catch(reason => {
          if (reason.response && reason.response.status === 404) {
            history.push(consts.routerPath.employeeIdNotFound);
          } else {
            history.push(consts.routerPath.fail);
          }
        });
      } catch (error) {
        console.log("error: " + error);
        // progressBar.hide();
      }
      // progressBar.hide();
    }
  }, [props.userProfile?.userPrincipalName]);

  const handleIntegerChange = (prop) => (event) => {
    setDonation({ ...donation, [prop]: event.target.value ? parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0 : 0 });
  };

  const handleDecimalChange = (prop) => (event) => {
    setDonation({ ...donation, [prop]: event.target.value  });
  };

  const handleRadioChange = (prop) => (event) => {
    setDonation({
      ...donation,
      [prop]: event.target.value === "true"
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    handleClickOpen();
  };

  const calculateTotal = () => {
    var total = donation.isEvenlyDistributed ?
    donation.d066
    :
    ((isNaN(donation.d133) || donation.d133 === '' ? 0 : parseFloat(donation.d133)) +
    (isNaN(donation.d058) || donation.d058 === '' ? 0 : parseFloat(donation.d058)) +
    (isNaN(donation.d060) || donation.d060 === '' ? 0 : parseFloat(donation.d060)) +
    (isNaN(donation.d063) || donation.d063 === '' ? 0 : parseFloat(donation.d063)) +
    (isNaN(donation.d064) || donation.d064 === '' ? 0 : parseFloat(donation.d064)) +
    (isNaN(donation.d065) || donation.d065 === '' ? 0 : parseFloat(donation.d065)) +
    (isNaN(donation.d068) || donation.d068 === '' ? 0 : parseFloat(donation.d068)) +
    (isNaN(donation.d118) || donation.d118 === '' ? 0 : parseFloat(donation.d118)) +
    (isNaN(donation.d119) || donation.d119 === '' ? 0 : parseFloat(donation.d119)) +
    (isNaN(donation.d131) || donation.d131 === '' ? 0 : parseFloat(donation.d131)));
    return parseFloat(parseFloat(total).toFixed(2));
  }

  const handleClickOpen = () => {
    setDialog(true);
  };

  const handleClose = async () => {
    setDialog(false);
  };

  const handleCloseAndSubmit = async () => {
    var requestData = donation;
    // clear out invalid data
    if (requestData.isEvenlyDistributed)
    {
      requestData.d133 = 0;
      requestData.d058 = 0;
      requestData.d060 = 0;
      requestData.d063 = 0;
      requestData.d064 = 0;
      requestData.d065 = 0;
      requestData.d068 = 0;
      requestData.d118 = 0;
      requestData.d119 = 0;
      requestData.d131 = 0;

    } else {
      requestData.d066 = 0;
    }

    // set empty text to 0
    requestData.d066 = requestData.d066 === '' ? 0 : requestData.d066;
    requestData.d133 = requestData.d133 === '' ? 0 : requestData.d133;
    requestData.d058 = requestData.d058 === '' ? 0 : requestData.d058;
    requestData.d060 = requestData.d060 === '' ? 0 : requestData.d060;
    requestData.d063 = requestData.d063 === '' ? 0 : requestData.d063;
    requestData.d064 = requestData.d064 === '' ? 0 : requestData.d064;
    requestData.d065 = requestData.d065 === '' ? 0 : requestData.d065;
    requestData.d068 = requestData.d068 === '' ? 0 : requestData.d068;
    requestData.d118 = requestData.d118 === '' ? 0 : requestData.d118;
    requestData.d119 = requestData.d119 === '' ? 0 : requestData.d119;
    requestData.d131 = requestData.d131 === '' ? 0 : requestData.d131;

    // progressBar.show("Loading...");
    setLoading(true);
    var response;
    try {
      response = await updateDonationsAsync(requestData, history);
    } catch (error) {
      // progressBar.hide();
    }
    setDialog(false);
    // progressBar.hide();
    setLoading(false);

    if (response.status === 204 || response.status === 200) {
      // redirect to success page
      history.push(consts.routerPath.success);
    } else {
      // redirect to fail page
      history.push(consts.routerPath.fail);
    }
  };

  const isTotalLessThan1 = () => {
    return (calculateTotal() < 1 && calculateTotal() !== 0);
  };

  return (
    <React.Fragment>
      <LoadingDialog isOpen={loadingOpen} message="Loading" />
      {isFormDisabled ? <Expired/> : <></>}
      <Container className={classes.Container} maxWidth="lg">
        <Card>
        <CardContent>
          <p>Hi {props.userProfile?.givenName}:</p>
          <p>Welcome to the Helping Hands Donation Form</p>
          <p><strong>Introducing SUPPORT ACT</strong></p>
          <p>From 16th February 2022 we are proud to welcome Support Act to the Helping Hands program and we farewell our charity partner of 14 years: Bush Heritage.
            For more information on Support Act, please visit <a href="https://supportact.org.au/" target="_blank" rel="noreferrer">https://supportact.org.au/</a></p>
          <p><strong>Giving Directly to Bush Heritage</strong></p>
          <p>Please note: If you have been making a dedicated weekly donation to Bush Heritage this amount has been added to Earth Squad in the form below, as both charities support Environmental action. This will take effect as of the 14th March 2022.
            <strong> If this is not what you would wish to happen, please make your changes below and submit.</strong>
          </p>
          <p><strong>Changes to Donations</strong></p>
          <p>If you already give through Helping Hands, your current donation amount(s) and charities are set out below. Should you wish to change your donation, please adjust the charities/amounts below and click the Submit button at the bottom. Any changes will take effect from 14th March 2022. Note any manual forms submitted to payroll will not be processed until after this date.</p>
          <p>If you are not already giving through Helping Hands, simply complete the form below with the charities and amount(s) you wish to donate.</p>
        </CardContent>
        </Card>
        <Card className={classes.FormCard}>
          <CardHeader
            title="Choose one of the options below"
            titleTypographyProps={{variant: 'h6'}}
          />
          <CardContent>
          <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  className='radio-group'
                  onChange={handleRadioChange('isEvenlyDistributed')}
                >
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={true}
                        checked={donation.isEvenlyDistributed}
                        control={<Radio/>}
                        label="I would like to donate the following amount from my weekly pay to be distributed evenly across all 10 Helping Hands charities including Support Act (min $1)"
                        disabled={isFormDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <FormControl>
                        <InputLabel htmlFor="d066-amount">{ donation.isEvenlyDistributed ? 'All ten charities' : 'Disabled' }</InputLabel>
                        <OutlinedInput
                          id="d066-amount"
                          type="text"
                          value={donation.d066}
                          onChange={handleIntegerChange('d066')}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          disabled={isFormDisabled || !donation.isEvenlyDistributed}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          label={ donation.isEvenlyDistributed ? 'All ten charities' : 'Disabled' }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={false}
                        checked={!donation.isEvenlyDistributed}
                        control={<Radio/>}
                        label="I would like to donate the following amount(s) from my weekly pay to the charities indicated below (min total $1, max 2 decimal places per donation)"
                        disabled={isFormDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                        <a className="charity-link" href="https://supportact.org.au/" target="_blank" rel="noreferrer"><strong>Support Act</strong></a> - Music Industry Support
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d133-amount">{ !donation.isEvenlyDistributed ? 'Support Act' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d133-amount"
                        type="text"
                        value={donation.d133}
                        onChange={handleDecimalChange('d133')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Support Act' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://australiacan.org.au/" target="_blank" rel="noreferrer"><strong>Companion Animal Network Australia</strong></a> - Animal Welfare
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d068-amount">{ !donation.isEvenlyDistributed ? 'Companion Animal Network Australia' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d068-amount"
                        type="text"
                        value={donation.d068}
                        onChange={handleDecimalChange('d068')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Companion Animal Network Australia' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://whatsyour2040.com/" target="_blank" rel="noreferrer"><strong>Earth Squad - 2040/Carbon8</strong></a> - Environment
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d131-amount">{ !donation.isEvenlyDistributed ? 'Earth Squad - 2040/Carbon8' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d131-amount"
                        type="text"
                        value={donation.d131}
                        onChange={handleDecimalChange('d131')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Earth Squad - 2040/Carbon8' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://www.hollows.org/" target="_blank" rel="noreferrer"><strong>Fred Hollows Foundation</strong></a> - Eye Health
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d064-amount">{ !donation.isEvenlyDistributed ? 'Fred Hollows Foundation' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d064-amount"
                        type="text"
                        value={donation.d064}
                        onChange={handleDecimalChange('d064')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Fred Hollows Foundation' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://www.kuc.org.au/" target="_blank" rel="noreferrer"><strong>Kids Under Cover</strong></a> - Youth Homelessness
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d118-amount">{ !donation.isEvenlyDistributed ? 'Kids Under Cover' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d118-amount"
                        type="text"
                        value={donation.d118}
                        onChange={handleDecimalChange('d118')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Kids Under Cover' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://www.mcauley.org.au/" target="_blank" rel="noreferrer"><strong>McAuley Community Services for Women</strong></a> - Family Violence Support
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d119-amount">{ !donation.isEvenlyDistributed ? 'McAuley Community Services' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d119-amount"
                        type="text"
                        value={donation.d119}
                        onChange={handleDecimalChange('d119')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'McAuley Community Services' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://www.oxfam.org.au/" target="_blank" rel="noreferrer"><strong>Oxfam</strong></a> - Poverty Alleviation
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d063-amount">{ !donation.isEvenlyDistributed ? 'Oxfam' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d063-amount"
                        type="text"
                        value={donation.d063}
                        onChange={handleDecimalChange('d063')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Oxfam' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://au.reachout.com/" target="_blank" rel="noreferrer"><strong>ReachOut</strong></a> - Youth Mental Health
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d058-amount">{ !donation.isEvenlyDistributed ? 'ReachOut' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d058-amount"
                        type="text"
                        value={donation.d058}
                        onChange={handleDecimalChange('d058')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'ReachOut' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://www.redkite.org.au/" target="_blank" rel="noreferrer"><strong>Redkite</strong></a> - Childhood Cancer
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d065-amount">{ !donation.isEvenlyDistributed ? 'Redkite' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d065-amount"
                        type="text"
                        value={donation.d065}
                        onChange={handleDecimalChange('d065')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'Redkite' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <a className="charity-link" href="https://www.songroom.org.au/" target="_blank" rel="noreferrer"><strong>The Song Room</strong></a> - Youth Education / Arts
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                      <InputLabel htmlFor="d060-amount">{ !donation.isEvenlyDistributed ? 'The Song Room' : 'Disabled' }</InputLabel>
                      <OutlinedInput
                        id="d060-amount"
                        type="text"
                        value={donation.d060}
                        onChange={handleDecimalChange('d060')}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        disabled={isFormDisabled || donation.isEvenlyDistributed}
                        inputProps={{ inputMode: 'decimal', pattern: '^\\d+\\.?\\d{0,2}$' }}
                        label={ !donation.isEvenlyDistributed ? 'The Song Room' : 'Disabled' }
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.Divider}>Summary</Divider>
                    </Grid>
                    <Grid item xs={6}>
                      <span><strong>TOTAL WEEKLY DONATION</strong></span>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="d060-amount">Total donation</InputLabel>
                      <Input
                          id="total-amount"
                          type="number"
                          value={calculateTotal()}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          readOnly
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    {isTotalLessThan1() ?
                      (<Alert variant="filled" severity="error">Total donation value has to be greater than $1.</Alert>)
                      :
                      (<></>)
                    }
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6} className={classes.SubmitButton} container justifyContent="left">
                      <Button
                        type="submit"
                        variant="contained"
                        className='submit-btn'
                        disabled={isFormDisabled || isTotalLessThan1()}
                      >
                          Submit
                      </Button>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
              {/* <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                {calculateTotal() === 0 ? ("Warning:") : ("Donation Summary:")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <SubmitSummary donation={donation} total={calculateTotal()} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleCloseAndSubmit} autoFocus>Agree</Button>
                </DialogActions>
              </Dialog> */}
              <ConfirmDialog
                dialogOpen={dialogOpen}
                handleClose={handleClose}
                handleCloseAndSubmit={handleCloseAndSubmit}
                donation={donation}
                total={calculateTotal()} />
          </Box>
          </form>
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
}
