import * as React from "react";
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from "@mui/material/Link";
import Button from '@mui/material/Button';

export default function EmployeeIdNotFound(props) {
    const { logout } = props;

    return (
        <React.Fragment>
            <Container className="container" maxWidth="lg">
                <Alert severity="warning">
                    <AlertTitle>Employee ID Not Found</AlertTitle>
                    Hi {props.userProfile?.givenName}, we could not locate your Employee ID in our records.<br/><br/>
                    Please ensure you are logged in with your individual account, as opposed to a shared account.<br/><br/>
                    If the problem persists after you log in with your individual account, then please contact JB Payroll <Link href="mailto:payroll@jbhifi.com.au">payroll@jbhifi.com.au</Link> via email.
                </Alert>
                <br/>
                <Button variant="outlined" onClick={logout}>Sign Out</Button>
            </Container>
        </React.Fragment>
    );
}
