import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

export default function SubmitSummary(props) {
  const donation = props.donation;
  const total = props.total;

  return (
    <React.Fragment>
    {total === 0 ? 
    (<Alert variant="filled" severity="error">Entering $0 will remove you from the Helping Hands program. Are you sure?</Alert>)
    :
    donation.isEvenlyDistributed ? 
    (<span>{`Evenly distributed to all ten charities: $${donation.d066}`}</span>)
    :
    (<Grid container spacing={2}>
    <Grid item xs={12} md={12}>
      <List>
          <ListItem>
            <ListItemText primary={`Support Act: $${donation.d133}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Companion Animal Network: $${donation.d068}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Earth Squad: $${donation.d131}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Fred Hollows Foundation: $${donation.d064}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Kids Under Cover: $${donation.d118}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`McAuley Community Services: $${donation.d119}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Oxfam: $${donation.d063}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`ReachOut: $${donation.d058}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Redkite: $${donation.d065}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`The Song Room: $${donation.d060}`} />
          </ListItem>
          <Divider className='divider'>Total</Divider>
          <ListItem>
            <ListItemText primary={`Total Weekly Donation: $${total}`} primaryTypographyProps={{ fontWeight: 'bold' }} />
          </ListItem>
      </List>
    </Grid>
    </Grid>)}
    </React.Fragment>
  );
}