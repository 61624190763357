import { createTheme } from "@material-ui/core/styles";

const jbColour = {
	yellow: "#ffec0f",
	orange: {
		primary: "#de8803",
		border: "#da6a00",
		background: "#fff9f0",
	},
	grey: {
		light: "#ebebeb",
	},
	blue: {
		border: "#095CA0",
		background: "#E8FAFF",
	},
	green: {
		primary: "#028702",
		border: "#028702",
		background: "#F2F9F2",
	},
	red: {
		primary: "#ff0000",
		border: "#e02020",
		background: "#fdf2f2",
	},
};

export const theme = createTheme({
	props: {
		MuiTypography: {
			variantMapping: {
				h1: "h1",
				h2: "div",
				h3: "div",
				h4: "div",
				h5: "div",
				h6: "div",
				subtitle1: "div",
				subtitle2: "div",
				body1: "div",
				body2: "div",
			},
		},
	},
	overrides: {
		MuiButton: {
			contained: {
				borderRadius: 0,
				color: "#ffffff",
				padding: "0 30px",
				boxShadow: "none",
				textTransform: "none",
				height: 32,
				fontSize: "10px",
			},
			containedPrimary: {
				backgroundColor: `${jbColour.green.primary} !important`,
			},
			containedSecondary: {
				backgroundColor: "#000000 !important",
			},
			outlined: {
				borderRadius: 0,
			},
		},
		MuiTypography: {
			h2: {
				fontSize: "20px",
				fontWeight: "bold",
			},
			h3: {
				fontSize: "14px",
			},
			h4: {
				fontSize: "12px",
				fontWeight: "bold",
			},
			h5: {
				fontSize: "12px",
			},
			h6: {
				fontSize: "13px",
				fontWeight: 1000,
			},
			body1: {
				fontSize: "12px",
				fontWeight: 500,
			},
			body2: {
				fontSize: "10px",
				fontWeight: "bold",
			},
		}
	},
	palette: {
		primary: {
			main: "#000000",
			light: "#cccccc",
			dark: "#000000",
			white: "#ffffff",
		},
		secondary: {
			main: jbColour.green.primary,
			error: jbColour.red.primary,
		},
		custom: {
			jbAppBar: {
				background: jbColour.yellow,
				color: "#000000",
			},
			search: {
				buttonHover: "#000000",
			},
			vendorInvoice: {
				errorColor: "#e65050",
				errorBackground: "#fdf2f2",
				qtyChangeBackground: jbColour.grey.light,
			},
			vendorInvoiceDetail: {
				lightBlue: jbColour.blue.background,
				darkBlue: jbColour.blue.border,
				lightRed: jbColour.red.background,
				darkRed: jbColour.red.border,
				lightGreen: jbColour.green.background,
				darkGreen: jbColour.green.border,
				lightOrange: jbColour.orange.background,
				darkOrange: jbColour.orange.border,
			},
			menu: {
				yellow: jbColour.yellow,
				default: "#ffffff",
				red: "#ff0000",
				green: "#00ff00",
			},
			warn: jbColour.orange.primary,
			shade: jbColour.grey.light,
		},
	},
	shape: {
		borderRadius: 0,
	},
});
