import React from "react";
import { Grid, Badge, IconButton, Container, Box, CssBaseline, AppBar, Toolbar, Typography, Hidden } from "@material-ui/core";
import UserDetail from "./UserDetail";
import { useTheme, withStyles, makeStyles } from "@material-ui/core/styles";
import logo from '../Image/HHLogoHoriz.png';
import consts from "../utils/consts";

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: 4,
		top: 24,
		border: `1px solid ${theme.palette.background.paper}`,
	},
}))(Badge);

const useStyles = makeStyles((theme) => ({
	menuIcon: {
		width: "44px",
		height: "44px",
		color: theme.palette.primary.main,
	},
	menuContainer: {
		padding: "12px 0 12px 0",
	},
	heading: {
		fontSize: "1.25rem",
		fontWeight: 500,
	},
	contentContainer: {
		maxWidth: "100vw",
		padding: 0,
		margin: 0,
	},
	title: {
		paddingRight: 10
	}
}));

export default function JbAppBar(props) {
	const theme = useTheme();
	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar style={theme.palette.custom.jbAppBar}>
				<Toolbar>
					<Grid container alignItems="center">
						<Grid item xs={3}><a href={consts.routerPath.home}><img src={logo} alt="Logo" height="75" /></a></Grid>
						<Grid item xs={8}></Grid>
						<Grid item xs={1}> 
							<UserDetail {...props} />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}