import * as React from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import consts from "../utils/consts";
import Button from '@mui/material/Button';

export default function Success(props) {
  const { logout } = props;

  return (
    <React.Fragment>
      <Container className='container' maxWidth="lg">
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Your Helping Hand donation has been updated. It will not take effect until <strong>14th March 2022</strong>.<br/>
          If you would like to make an update again, <Link href={consts.routerPath.home}>Click Here</Link> to return to donation form
        </Alert>
        <br/>
        <Button variant="outlined" onClick={logout}>Sign Out</Button>
      </Container>
    </React.Fragment>
  );
}