const consts = {
	routerPath: {
		home: "/",
		helpinghands: "/hhform",
		success: "/success",
		fail: "/fail",
		notapplicable: "/na",
		expired: "/expired",
		employeeIdNotFound: "/employeeIdNotFound"
	}
};

export default consts;
