import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Dialog, Grid, Container, Box, CircularProgress } from "@material-ui/core";
function LoadingDialog(props) {
	const { isOpen, message, onLoadingClose } = props;
	return (
		<Dialog aria-labelledby="loading-dialog-title" open={isOpen} onClose={onLoadingClose}>
			<Container>
				<DialogTitle id="loading-dialog-title">Loading</DialogTitle>
				<Container>
					<Grid container direction="column" display="flex" justifyContent="center" alignItems="center">
						<Grid item>
							<CircularProgress size={80} color="secondary" />
						</Grid>
						<Grid item>
							<Box>{message}</Box>
						</Grid>
					</Grid>
				</Container>
			</Container>
		</Dialog>
	);
}
LoadingDialog.propTypes = {
	isOpen: PropTypes.bool,
	message: PropTypes.string,
	onLoadingClose: PropTypes.func,
};

export default LoadingDialog;
