import axios from "axios";
import camelKeys from "camelcase-keys";
import { apiTokenRequest, authProvider } from "../Auth/utils/AuthProvider";
import consts from "../utils/consts";

async function getAuthHeadersAsync() {
	const token = await authProvider.getToken(apiTokenRequest);
	const headers = {
		Authorization: "Bearer " + token.accessToken,
	};
	return headers;
}

async function getCurrentDonationsAsync() {
	var authToken = await getAuthHeadersAsync();
	return new Promise((resolve, reject) => {
		axios
			.get(`${process.env.REACT_APP_API_APIM_URL}${process.env.REACT_APP_API_GET_DONATION_PATH}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': authToken.Authorization
				},
				responseType: "json",
			})
			.then((response) => {
				resolve(camelKeys(response.data, { deep: true }));
			})
			.catch((error) => {
				reject(error);
			});
	});
}

async function updateDonationsAsync(requestData, history) {
	var authToken = await getAuthHeadersAsync();
	return new Promise((resolve, reject) => {
		axios
        	.put(`${process.env.REACT_APP_API_APIM_URL}${process.env.REACT_APP_API_UPDATE_DONATION_PATH}`
            , JSON.stringify(requestData)
            , {
                headers: {
                    'Content-Type': 'application/json',
					'Authorization': authToken.Authorization
                }
            })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
				history.push(consts.routerPath.fail);
			});
	});
}

export {
	getCurrentDonationsAsync,
	updateDonationsAsync
};
