import React, { memo, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { Grid, Typography, CardActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
	avatarBig: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	},
}));

const UserDetail = memo((props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useStyles();
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	if (props.userProfile) {
		return (
			<React.Fragment>
				<Avatar src={`data:image/png;base64,${props.userPhoto}`} onClick={handleMenu} />
				<Popover
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<Card>
						<CardContent>
							<Grid container spacing={1} justifyContent="flex-start" alignItems="center">
								<Grid item>
									<Avatar className={classes.avatarBig} src={`data:image/png;base64,${props.userPhoto}`} />
								</Grid>
								<Grid item>
									<Typography variant="h6" component="div">
										{props.userProfile.displayName}
									</Typography>
									<Typography variant="subtitle1" component="div">
										{props.userProfile.staffId}
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions>
							<Grid container justifyContent="center" alignItems="center">
								<Button variant="contained" color="primary" onClick={props.logout}>
									Sign out
								</Button>
							</Grid>
						</CardActions>
					</Card>
				</Popover>
			</React.Fragment>
		);
	}
	return <div></div>;
});

export default UserDetail;
