import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let reactPlugin = null;
let appInsights = null;
export const initAppInsight = (history) => {
	reactPlugin = new ReactPlugin();
	appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
			enableCorsCorrelation: true,
			disableFetchTracking: true,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: history },
			},
		},
	});
	appInsights.loadAppInsights();
	appInsights.trackPageView();
};

export const getAppInsights = () => appInsights;
export const getReactPlugin = () => reactPlugin;


export const initializeTelemtry = (staffId, displayName) => {
    getAppInsights().setAuthenticatedUserContext(staffId, undefined, true);
    getAppInsights().addTelemetryInitializer((envelope) => {
        const tags = envelope.tags;
        if (tags && tags["ai.user.authUserId"]) {
            tags["ai.user.id"] = tags["ai.user.authUserId"];
            tags["ai.user.name"] = displayName;
        }
        envelope.data.userId = staffId;
        envelope.data.userName = displayName;
    });
};

