import React, { useState, useEffect, useCallback, useContext } from "react";
import { authProvider, AuthenticationState } from "./utils/AuthProvider";
import { getUserProfileAsync, getUserPhotoAsync } from "./utils/userProfile";
import PageContext from "../Common/PageContext";
// import NoAccess from "../NoAccess/NoAccess";
// import Splash from "../Common/Splash";
// import { Lock } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    noAccessIcon: {
        color: theme.palette.primary.white,
		fontSize: 64
    }
  }));

const AzureADApp = (Component) => {
	const AzureAdAppComponent = (props) => {
		const classes = useStyles();
		const [authenticationState, setAuthenticationState] = useState(authProvider.authenticationState);
		const [account, setAccount] = useState(null);
		const [accessToken, setAccessToken] = useState(null);
		const [userProfile, setUserProfile] = useState(null);
		const [userPhoto, setUserPhoto] = useState(null);
		const [error, setError] = useState(null);
		const [contextData, setContextData] = useContext(PageContext);

		const loginCallBack = useCallback(() => {
			if (authenticationState !== AuthenticationState.UnAuthenticated || error) {
				return;
			}
			setAuthenticationState(AuthenticationState.InProgress);
			authProvider.login();
		}, [authenticationState, error]);

		async function logout() {
			authProvider.logout();
		}

		useEffect(() => {
			function handleResponse(tokenResponse) {
				if (tokenResponse) {
					setAccount(tokenResponse.account);
					setAccessToken(tokenResponse.accessToken);
					setAuthenticationState(AuthenticationState.Authenticated);
				} else {
					loginCallBack();
				}
			}

			authProvider
				.handleRedirectPromise()
				.then(handleResponse)
				.catch((authError) => {
					setAuthenticationState(AuthenticationState.UnAuthenticated);
					console.error("error happened", authError);
					setError(authError.errorMessage);
				});
		}, [loginCallBack]);

		useEffect(() => {
			if (accessToken) {
				async function setupUserDetailsAsync() {
					const userProfileFromGraph = await getUserProfileAsync(accessToken);
					const userProfileWithRoles = { ...userProfileFromGraph, roles: account.idTokenClaims.roles };

					setUserProfile(userProfileWithRoles);

					const userProfileWithStoreNumber = !userProfileWithRoles?.storeNumber
						? { ...userProfileWithRoles, storeNumber: 1 }
						: userProfileWithRoles;

					setContextData({ ...contextData, userProfile: userProfileWithStoreNumber, accessToken });

					const photo = await getUserPhotoAsync(accessToken);
					setUserPhoto(photo);
				}
				setupUserDetailsAsync();
			}
		}, [accessToken]);

		if (authenticationState === AuthenticationState.InProgress) {
			return <div>authenticating...</div>;
		}

		const isLoaded = () => contextData !== null && Object.keys(contextData).length > 0;
		if(error?.includes("assigned to a role")) {
			// return (<Splash title="Product App" icon={<Lock className={classes.noAccessIcon}/>} secondaryText="No Access! Contact IT Engineering - Inventory Team via Zendesk." showProgress={false}/>)

			return (<div>No Access!</div>);
		} else {
			return (
				<>
					{isLoaded() && (
						<Component
							{...props}
							account={account}
							userProfile={userProfile}
							userPhoto={userPhoto}
							accessToken={accessToken}
							authenticationState={authenticationState}
							error={error}
							login={() => {
								authProvider.login();
							}}
							logout={logout}
						/>
					)}
				</>
			);
		}
	};
	return AzureAdAppComponent;
};
export default AzureADApp;
