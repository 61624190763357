import * as React from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

export default function NotApplicable(props) {

  return (
    <React.Fragment>
      <Container className='container' maxWidth="lg">
        <Alert severity="warning">
          <AlertTitle>Not Applicable</AlertTitle>
          Hi {props.userProfile?.givenName}, your current donation settings are managed separately by Payroll on a percentage basis
          <br/>Please contact Carmel Higgs <Link href="mailto:carmel.higgs@jbhifi.com.au">carmel.higgs@jbhifi.com.au</Link> to manage your donations.
        </Alert>
      </Container>
    </React.Fragment>
  );
}