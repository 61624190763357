import React, { useContext } from "react";
import PageContext from "./Common/PageContext";
import './App.css';
import HelpingHandForm from './Page/HelpingHandForm';
import AzureAdApp from "./Auth/AzureAdApp";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { initAppInsight, getReactPlugin, getAppInsights, initializeTelemtry } from "./utils/telemetryService";
import JbAppBar from "./AppBar/JbAppBar";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import consts from "./utils/consts";
import Success from "./Page/Success";
import Fail from "./Page/Fail";
import NotApplicable from "./Page/NotApplicable";
import Expired from "./Page/Expired";
import EmployeeIdNotFound from "./Page/EmployeeIdNotFound";

const history = createBrowserHistory({});
initAppInsight(history);
const reactPlugin = getReactPlugin();

const App = (props) => {
  const { logout } = props;
  const [contextData] = useContext(PageContext);
  let naEmpArray = [];
  let isEmployeeNotApplicable = false;
  if (process.env.REACT_APP_NA_EMP_CODE) {
    naEmpArray = process.env.REACT_APP_NA_EMP_CODE.split(",");
    isEmployeeNotApplicable = naEmpArray.includes(contextData?.userProfile?.staffId);
  }
  const isFormDisabled = process.env.REACT_APP_FORM_DISABLED === 'true' ? true : false;

  	// Initialize App Insights Telemtry
	if (getAppInsights().queue !== undefined) {
		getAppInsights().queue.push(initializeTelemtry);
	} else {
		initializeTelemtry(contextData?.userProfile?.staffId, contextData?.userProfile?.displayName, contextData?.userProfile?.storeNumber);
	}

  return (
    <div className="App">
      <JbAppBar {...props} >
			</JbAppBar>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={`${consts.routerPath.home}`}
            render={() => {
                return (
                  isEmployeeNotApplicable ?
                  <Redirect to={consts.routerPath.notapplicable} />
                  :
                  isFormDisabled ?
                  <Redirect to={consts.routerPath.expired} />
                  :
                  <Redirect to={consts.routerPath.helpinghands} />
                )
            }}
          />
          <Route
            exact
            path={`${consts.routerPath.helpinghands}`}
            render={(props) => <HelpingHandForm {...props} userProfile={contextData?.userProfile} />}
          />
          <Route
            exact
            path={`${consts.routerPath.success}`}
            render={(props) => <Success {...props} logout={logout} />}
          />
          <Route
            exact
            path={`${consts.routerPath.fail}`}
            render={(props) => <Fail {...props} logout={logout} />}
          />
          <Route
            exact
            path={`${consts.routerPath.notapplicable}`}
            render={(props) => <NotApplicable {...props} userProfile={contextData?.userProfile} />}
          />
          <Route
            exact
            path={`${consts.routerPath.expired}`}
            render={(props) => <Expired {...props} />}
          />
          <Route
            exact
            path={`${consts.routerPath.employeeIdNotFound}`}
            render={(props) => <EmployeeIdNotFound {...props} userProfile={contextData?.userProfile} />}
            />
        </Switch>
      </Router>
    </div>
  );
}

export default AzureAdApp(withAITracking(reactPlugin, App));
