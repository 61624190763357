import React from "react";
import PropTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import SubmitSummary from './SubmitSummary';

function ConfirmDialog(props) {
	const { dialogOpen, handleClose, handleCloseAndSubmit, donation, total } = props;
	return (
		<Dialog
			open={dialogOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{total === 0 ? ("Warning:") : ("Donation Summary:")}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				<SubmitSummary donation={donation} total={total} />
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose}>Cancel</Button>
			<Button onClick={handleCloseAndSubmit} autoFocus>Agree</Button>
			</DialogActions>
		</Dialog>
	);
}
ConfirmDialog.propTypes = {
	dialogOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	handleCloseAndSubmit: PropTypes.func,
	donation: PropTypes.object,
	total: PropTypes.number,
};

export default ConfirmDialog;
