import * as React from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

export default function Expired(props) {

  return (
    <React.Fragment>
      <Container className='container' maxWidth="lg">
      <Alert severity="error">
        <AlertTitle>Helping Hands Donation Form update has been disabled as of <strong>8th March 2022</strong>.</AlertTitle>
        If you wish to make further updates, please use the form available on Backstage at <Link href="https://jbhifi.sharepoint.com/sites/Backstage/Pages/HelpingHands.aspx">https://jbhifi.sharepoint.com/sites/Backstage/Pages/HelpingHands.aspx</Link> and submit to Payroll <Link href="mailto:payroll@jbhifi.com.au">payroll@jbhifi.com.au</Link>.
      </Alert>
      </Container>
    </React.Fragment>
  );
}