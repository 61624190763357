import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import PageProvider from "./Common/PageProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import LoadingProvider from "./Common/LoadingProvider";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <PageProvider>
      {/* <LoadingProvider> */}
        <App />
      {/* </LoadingProvider> */}
    </PageProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
