import * as React from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import consts from "../utils/consts";
import Button from '@mui/material/Button';

export default function Fail(props) {
  const { logout } = props;

  return (
    <React.Fragment>
      <Container className='container' maxWidth="lg">
        <Alert severity="error">
          <AlertTitle>Oops there's been an error</AlertTitle>
          Please return to donation form by <Link href={consts.routerPath.home}>clicking here</Link> and try again.
          <br/>If your donation update continues to fail, please contact JB Payroll <Link href="mailto:payroll@jbhifi.com.au">payroll@jbhifi.com.au</Link> via email.
        </Alert>
        <br/>
        <Button variant="outlined" onClick={logout}>Sign Out</Button>
      </Container>
    </React.Fragment>
  );
}